<template>
    <div>
        <template v-if="admin">
			<template v-if="chargement">
				<h2>Chargement ...</h2>
			</template>
			<template v-else>
				<vx-card>
					<vs-row>
						<vs-col vs-w="6" style="padding-right:5px;">
							Date début:
							<cDate month="1" v-model="start_date" class="w-full select-large"/>
						</vs-col>
						<vs-col vs-w="6" style="padding-left:5px;">
							Date fin:
							<cDate month="1" v-model="end_date" class="w-full select-large"/>
						</vs-col>
					</vs-row>
					<br/>
					<vs-button @click="activePrompt=true" class="float-right">Export</vs-button>
					<br/>
					<br/>
				</vx-card>
				<br/>
				<br/>
				<br/>
				<br/>
				<h2>Liste comptes</h2>
				<hr/>
				<vs-table v-model="compte_select" pagination max-items="20" :data="compte_list" @selected="editCompte" noDataText="Aucun résultat">
					<!-- header table -->
					<template slot="thead">
						<vs-th sort-key="name">Nom du compte</vs-th>
						<vs-th sort-key="value">Valeur</vs-th>
					</template>

					<!--Body table-->
					<template slot-scope="{data}">
						<vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
							<vs-td :data="data[indextr].name">
								<span>{{ data[indextr].name }}</span>
							</vs-td>
							<vs-td :data="data[indextr].value">
								<span v-on:click="editCompte( data[indextr].name )" v-html="data[indextr].value" ></span>
							</vs-td>
						</vs-tr>
					</template>
				</vs-table>
				<br/>
				<br/>
				<h2>Visualisation</h2>
				<hr/>
				<vs-table v-model="visualisation_select" pagination max-items="20" :data="visualisation_list" @selected="editCompte">
					<!-- header table -->
					<template slot="thead">
						<vs-th sort-key="o">Operation</vs-th>
						<vs-th sort-key="a">Date</vs-th>
						<vs-th sort-key="b">Compte</vs-th>
						<vs-th sort-key="c">Facture</vs-th>
						<vs-th sort-key="d">Libelle</vs-th>
						<vs-th sort-key="e">Montant</vs-th>
					</template>

					<!--Body table-->
					<template slot-scope="{data}">
						<vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
							<vs-td :data="data[indextr].o">
								<span>{{ data[indextr].o }}</span>
							</vs-td>
							<vs-td :data="data[indextr].a">
								<span>{{ data[indextr].a }}</span>
							</vs-td>
							<vs-td :data="data[indextr].b">
								<span v-html="data[indextr].b"></span>
							</vs-td>
							<vs-td :data="data[indextr].c">
								<span>{{ data[indextr].c }}</span>
							</vs-td>
							<vs-td :data="data[indextr].d">
								<span>{{ data[indextr].d }}</span>
							</vs-td>
							<vs-td :data="data[indextr].e">
								<span>{{ data[indextr].e }}</span>
							</vs-td>
						</vs-tr>
					</template>
				</vs-table>

				<vs-prompt title="table" class="export-options" @cancel="clearFields" @accept="exportToExcel" accept-text="Exporter" @close="clearFields" :active.sync="activePrompt">
					<vs-input v-model="fileName" placeholder="Nom du fichier" class="w-full" />
					<v-select v-model="selectedFormat" :options="formats" class="my-4" />
					<div class="flex">
						<span class="mr-4">Espacement:</span>
						<vs-switch v-model="cellAutoWidth">Espacement</vs-switch>
					</div>
				</vs-prompt>

			</template>
        </template>
        <template v-else >
            <h2>Accès non autorisé.</h2>
        </template>
    </div>
</template>



<style lang="scss">
</style>

<script>
import vSelect from 'vue-select'

import Utils from '@/assets/utils/utils';
import autoRefresh from '@/assets/utils/autoRefresh'
import cDate from '@/components/app/date'

import Member from '@/database/models/member'
import Invoice from '@/database/models/invoice'
import Item from '@/database/models/item'
import ListCompte from '@/database/models/list_compte'

var GLOBAL_VAR_COMPTE = [];
var GLOBAL_VAR_FACTURE = [];

export default {
    components: {
		vSelect,
        cDate,
    },
    data(){
        return{
			admin:false,
			chargement:false,

			start_date: new Date(),
			end_date: new Date(),

            compte_list:[],
            compte_select:[],

            facture_list:[],

            visualisation_list:[],
			visualisation_select:[],
			

			//export
			fileName: '',
			formats:['xlsx', 'csv', 'txt'],
			cellAutoWidth: true,
			selectedFormat: 'xlsx',
			headerTitle: ['Operation','Date','Compte','Facture','Libelle','Montant'],
			headerVal: ['o','a','b','c','d','e'],
			activePrompt: false,
			
			devise: {}
        }
	},
	mounted:function()
	{
        //autoRefresh
        autoRefresh.SetCallback(()=>
        {
					this.$srvApi.Req('get', 'public/currency')
					.then(( rep )=>{
							this.devise = rep.data;
					})
            this.RoleAdmin();
            //refresh
            ListCompte.getTabAllMemory((list_data)=>
            {
                GLOBAL_VAR_COMPTE = list_data;

                //print facture
                Invoice.getTabAllMemoryClone(async (list_invoice)=>
                {
                    for( var i=0; i<list_invoice.length; i++ )
                    {
                        list_invoice[i].items  = await Item.AwaitGetTabAllMemoryKey(list_invoice[i].uid);
                        list_invoice[i].member = await Member.AwaitGetMemory(list_invoice[i].member);
                    }

					GLOBAL_VAR_FACTURE = list_invoice;
                    updateFacTable( this );
                });
                
            });
        });
        autoRefresh.refresh();
    },
    watch:{
        $route (){
		},
		start_date(){
			updateFacTable( this );
			this.reload();
		},
		end_date(){
			updateFacTable( this );
			this.reload();
		}
    },
    methods:{
		RoleAdmin()
        {
            this.admin = false;
                  //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.admin = mbr.type.toLowerCase()=="a"||mbr.type.toLowerCase()=="c";
            });
		},
		reload()
		{
			lock_fonc = false;
			autoRefresh.refresh();
		},

        editCompte( name )
        {
			if(typeof(name) != "string")
				return;
			
			let value = prompt('Valeur comptable pour "'+name+'"');
			if(!value)
				return;
			this.chargement = true;

			let foncAdd = ()=>
			{
				//Add nex compte
				ListCompte.add({
					name:name,
					value:value
				})
				.then(()=>{
					setTimeout(()=>{
						lock_fonc = false;
						autoRefresh.refresh();
					}, 1000);
				})
			};

			let cc = undefined;
			for( var i=0; i<GLOBAL_VAR_COMPTE.length; i++)
			if( GLOBAL_VAR_COMPTE[i].name == name )
				cc = GLOBAL_VAR_COMPTE[i];

			//remove old compte
			if(cc)
			ListCompte.remove(cc.uid)
			.then(foncAdd)
			.catch(foncAdd);
			else
				foncAdd();
		},
		


		//----------------------------------------------------
		//export excel
		//----------------------------------------------------
		clearFields ()
		{
			this.filename = ''
			this.cellAutoWidth = true
			this.selectedFormat = 'xlsx'
		},
		exportToExcel ()
		{
			let root = this;

			import('@/assets/utils/Export2Excel')
			.then(excel => {
				//get data
				let list = root.visualisation_list

				//Parse correction
				let final_export = [];
				list = JSON.parse(JSON.stringify(list));
				for( var i in list )
				{
					let buffer = JSON.parse(JSON.stringify(list[i]));
					if(buffer.b == '<span style="color:red;">Compte manquant !</span>')
						buffer.b = '';
					final_export.push( buffer );
				}

				//prépare data
				const data = this.formatJson(root.headerVal, final_export);

				//export
				excel.export_json_to_excel(
				{
					header: root.headerTitle,
					data,
					filename : root.fileName,
					autoWidth: root.cellAutoWidth,
					bookType : root.selectedFormat
				});
				root.clearFields();
			});
		},
		formatJson (filterVal, jsonData)
		{
			let data = jsonData.map(v => filterVal.map(function(j) 
			{
				//Correction de champs ICI
				//if(j == 'uid')
				//	v[j] = undefined;
				return v[j];
			}));

			//BUG! enlever les duplications !!
			filterVal = Utils.removeDuplicate(filterVal);
			//Fin
			return data;
		},
    }
};







function findCompte( val )
{
	val = val.replace(/-/i, '');

	for( var i=0; i<GLOBAL_VAR_COMPTE.length; i++)
	if( GLOBAL_VAR_COMPTE[i].name == val )
		return GLOBAL_VAR_COMPTE[i].value;
	return '<span style="color:red;">Compte manquant !</span>';
}




let lock_fonc = false;
function updateFacTable( root )
{
    if(lock_fonc)
        return;
	lock_fonc = true;
	root.chargement = true;

	//Calcule date de début
	root.start_date.setDate(1);
	root.start_date.setHours(0);
	root.start_date.setMinutes(0);
	let date_min = root.start_date.getTime();

	//Calcule date de fin
	let final_date = new Date( root.end_date );
	final_date.setMonth( final_date.getMonth()+1 );
	final_date.setDate(1);
	final_date.setHours(0);
	final_date.setMinutes(0);
	let date_max = final_date.getTime();


    var table_liste_compte = [];
	let tab_return = [];

    //TABLE CONTA
    var buffer_conta = {};
	for(var i=0; i<GLOBAL_VAR_FACTURE.length; i++ )
	{
		buffer = GLOBAL_VAR_FACTURE[i];
		let time = (new Date(buffer.createAt)).getTime();
		if(time>date_min && time<date_max)
		{
			// var client;
			
			
			if(buffer.member == undefined){
				
					buffer.member ={
						first_name:"membre supprimé",
						last_name:"membre supprimé"
					}
				
				
			}
			
			var client = Utils.StrCapital(buffer.member.first_name+' '+buffer.member.last_name);
			
			var total = 0;
			var d = new Date( buffer.createAt );
			var date   = d.getDate()+'/'+(d.getMonth()+1)+'/'+d.getFullYear();
			//console.log( buffer )
			// if (buffer.type == "re")
			let facturePrele = buffer.type == "fa" && buffer.mollie_id != null
			if( buffer.type == "re" || ( facturePrele && (buffer.mollie_id).search("tr_") == 0 ) )
			{
				total = 0;
				//Encaissement
				if( buffer.pay_cb != 0 )
				{
					total += buffer.pay_cb;
					buffer_conta = {};
					buffer_conta.o = 'encaissement CB';
					buffer_conta.a = date;
					buffer_conta.b = findCompte('encaissement CB');
					buffer_conta.c = buffer.number;
					buffer_conta.d = 'CB'+' + '+client;
					buffer_conta.e = (buffer.pay_cb*-1).toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );	

					table_liste_compte.push({'name':'encaissement CB'});
				}
				if( buffer.pay_check != 0 )
				{
					total += buffer.pay_check;
					buffer_conta = {};
					buffer_conta.o = 'encaissement chèque';
					buffer_conta.a = date;
					buffer_conta.b = findCompte( 'encaissement chèque');
					buffer_conta.c = buffer.number;
					buffer_conta.d = 'cheque'+' + '+client;
					buffer_conta.e = (buffer.pay_check*-1).toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );	

					table_liste_compte.push({'name':'encaissement chèque'});
				}
				if( buffer.pay_money != 0 )
				{
					total += buffer.pay_money;
					buffer_conta = {};
					buffer_conta.o = 'encaissement espèces';
					buffer_conta.a = date;
					buffer_conta.b = findCompte( 'encaissement espèces');
					buffer_conta.c = buffer.number;
					buffer_conta.d = 'espece'+' + '+client;
					buffer_conta.e = (buffer.pay_money*-1).toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );	

					table_liste_compte.push({'name':'encaissement espèces'});
				}
				if( buffer.pay_prele != 0 )
				{
					total += buffer.pay_prele;
					buffer_conta = {};
					buffer_conta.o = 'encaissement prélèvement';
					buffer_conta.a = date;
					buffer_conta.b = findCompte( 'encaissement prélèvement');
					buffer_conta.c = buffer.number;
					buffer_conta.d = 'prelevement'+' + '+client;
					buffer_conta.e = (buffer.pay_prele*-1).toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );	

					table_liste_compte.push({'name':'encaissement prélèvement'});
				}

				//Créance encaissement
				buffer_conta = {};
				buffer_conta.o = 'créance 2';
				buffer_conta.a = date;
				buffer_conta.b = findCompte( 'créance');
				buffer_conta.c = buffer.number;
				(facturePrele && (buffer.mollie_id).search("tr_") == 0)? buffer_conta.d = 'abonnement + '+client : buffer_conta.d = 'différé + '+client;
				(facturePrele && (buffer.mollie_id).search("tr_") == 0)? buffer_conta.e = (buffer.pay_prele).toFixed(root.devise.nb_decimal) : buffer_conta.e = (buffer.pay_dif*-1).toFixed(root.devise.nb_decimal);
				tab_return.push( buffer_conta );

				table_liste_compte.push({'name':'créance'});
			}
			if ( buffer.type == "fa" )
			{
				if (facturePrele) {
					if ( (buffer.mollie_id).search("tr_") == 0 ) {
						total = 0
					}
				}
				//build item
				for( var p=0; p<buffer.items.length; p++)
				{
					var item = buffer.items[p];

					//VENTE
					buffer_conta = {};
					buffer_conta.o = 'vente '+item.tva;
					buffer_conta.a = date;

					//Compte directement dans item ?
					if(item.compte_produit == null || item.compte_produit=='')
						buffer_conta.b = findCompte( 'vente '+item.tva );
					else
						buffer_conta.b = item.compte_produit;

					buffer_conta.c = buffer.number;
					buffer_conta.d = item.name+' + '+client;
					buffer_conta.e = (Number(item.prix)).toFixed(root.devise.nb_decimal);

					tab_return.push( buffer_conta );

					table_liste_compte.push({'name':'vente '+item.tva});


					//TVA
					buffer_conta = {};
					buffer_conta.o = 'tva '+item.tva;
					buffer_conta.a = date;

					//Compte directement dans item ?
					if(item.compte_tva == null || item.compte_tva=='')
						buffer_conta.b = findCompte( 'tva '+item.tva );
					else
						buffer_conta.b = item.compte_tva;

					buffer_conta.c = buffer.number;
					buffer_conta.d = item.name+' + '+client;
					var t = (Number(item.prix)*Number(item.tva))/100;//calcule tva
					buffer_conta.e = t.toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );

					table_liste_compte.push({'name':'tva '+item.tva});

					//ADD TOTAL
					total += Number(item.prix)+t;
				}
				//Créance produit
				buffer_conta = {};
				buffer_conta.o = 'créance 3';
				buffer_conta.a = date;
				buffer_conta.b = findCompte( 'créance');
				buffer_conta.c = buffer.number;
				buffer_conta.d = client;
				buffer_conta.e = (total*-1).toFixed(root.devise.nb_decimal);
				tab_return.push( buffer_conta );
				total = 0;
				//Encaissement
				if( buffer.pay_cb != 0 )
				{
					total += buffer.pay_cb;
					buffer_conta = {};
					buffer_conta.o = 'encaissement CB';
					buffer_conta.a = date;
					buffer_conta.b = findCompte( 'encaissement CB');
					buffer_conta.c = buffer.number;
					buffer_conta.d = 'CB'+' + '+client;
					buffer_conta.e = (buffer.pay_cb*-1).toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );	

					table_liste_compte.push({'name':'encaissement CB'});
				}
				if( buffer.pay_check != 0 )
				{
					total += buffer.pay_check;
					buffer_conta = {};
					buffer_conta.o = 'encaissement chèque';
					buffer_conta.a = date;
					buffer_conta.b = findCompte( 'encaissement chèque');
					buffer_conta.c = buffer.number;
					buffer_conta.d = 'cheque'+' + '+client;
					buffer_conta.e = (buffer.pay_check*-1).toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );	

					table_liste_compte.push({'name':'encaissement chèque'});
				}
				if( buffer.pay_money != 0 )
				{
					total += buffer.pay_money;
					buffer_conta = {};
					buffer_conta.o = 'encaissement espèces';
					buffer_conta.a = date;
					buffer_conta.b = findCompte( 'encaissement espèces');
					buffer_conta.c = buffer.number;
					buffer_conta.d = 'espece'+' + '+client;
					buffer_conta.e = (buffer.pay_money*-1).toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );	

					table_liste_compte.push({'name':'encaissement espèces'});
				}
				// if( buffer.pay_prele != 0 && facturePrele)
				// {
				// 	total += buffer.pay_prele;
				// 	buffer_conta = {};
				// 	buffer_conta.o = 'encaissement prélèvement';
				// 	buffer_conta.a = date;
				// 	buffer_conta.b = findCompte( 'encaissement prélèvement');
				// 	buffer_conta.c = buffer.number;
				// 	buffer_conta.d = 'espece'+' + '+client;
				// 	buffer_conta.e = (buffer.pay_prele*-1).toFixed(root.devise.nb_decimal);
				// 	tab_return.push( buffer_conta );	

				// 	table_liste_compte.push({'name':'encaissement prélèvement'});
				// }

				// Créance encaissement
				if( total != 0 )
				{
					buffer_conta = {};
					buffer_conta.o = 'créance';
					buffer_conta.a = date;
					buffer_conta.b = findCompte( 'créance');
					buffer_conta.c = buffer.number;
					buffer_conta.d = 'mode P + '+client;
					buffer_conta.e = total.toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );

					table_liste_compte.push({'name':'créance'});
				}
			}
			if (buffer.type == "av")
			{
				total = 0;

				//build item
				for( p=0; p<buffer.items.length; p++)
				{
					item = buffer.items[p];
					
					//TVA
					buffer_conta = {};
					buffer_conta.o = 'tva '+(item.tva);
					buffer_conta.a = date;

					//Compte dans item ou default
					if(item.compte_tva == null || item.compte_tva=='')
						buffer_conta.b = findCompte( 'tva '+(item.tva) );
					else
						buffer_conta.b = item.compte_tva;


					buffer_conta.c = buffer.number;
					buffer_conta.d = item.name+' + '+client;
					t = (Number(item.prix)*Number(item.tva))/100;//calcule tva
					buffer_conta.e = (t*-1).toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );

					table_liste_compte.push({'name':'tva '+item.tva});

					//VENTE
					buffer_conta = {};
					buffer_conta.o = 'vente '+(item.tva);
					buffer_conta.a = date;

					//Compte dans item ou default
					if(item.compte_produit == null || item.compte_produit=='')
						buffer_conta.b = findCompte( 'vente '+(item.tva) );
					else
						buffer_conta.b = item.compte_produit;

					buffer_conta.c = buffer.number;
					buffer_conta.d = item.name+' + '+client;
					buffer_conta.e = (Number(item.prix)).toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );

					table_liste_compte.push({'name':'vente '+item.tva});

					//ADD TOTAL
					total += Number(item.prix)-t;
				}

				//Créance produit
				buffer_conta = {};
				buffer_conta.o = 'créance';
				buffer_conta.a = date;
				buffer_conta.b = findCompte( 'créance');
				buffer_conta.c = buffer.number;
				buffer_conta.d = client;
				buffer_conta.e = (total*-1).toFixed(root.devise.nb_decimal);
				tab_return.push( buffer_conta );

				total = 0;
				if( buffer.pay_cb != 0 )
				{
					total += buffer.pay_cb;
					buffer_conta = {};
					buffer_conta.o = 'encaissement CB';
					buffer_conta.a = date;
					buffer_conta.b = findCompte( 'encaissement CB');
					buffer_conta.c = buffer.number;
					buffer_conta.d = 'CB'+' + '+client;
					buffer_conta.e = (buffer.pay_cb*-1).toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );	

					table_liste_compte.push({'name':'encaissement CB'});
				}
				if( buffer.pay_check != 0 )
				{
					total += buffer.pay_check;
					buffer_conta = {};
					buffer_conta.o = 'encaissement chèque';
					buffer_conta.a = date;
					buffer_conta.b = findCompte( 'encaissement chèque');
					buffer_conta.c = buffer.number;
					buffer_conta.d = 'cheque'+' + '+client;
					buffer_conta.e = (buffer.pay_check*-1).toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );	

					table_liste_compte.push({'name':'encaissement chèque'});
				}
				if( buffer.pay_money != 0 )
				{
					total += buffer.pay_money;
					buffer_conta = {};
					buffer_conta.o = 'encaissement espèces';
					buffer_conta.a = date;
					buffer_conta.b = findCompte( 'encaissement espèces');
					buffer_conta.c = buffer.number;
					buffer_conta.d = 'espece'+' + '+client;
					buffer_conta.e = (buffer.pay_money*-1).toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );	

					table_liste_compte.push({'name':'encaissement espèces'});
				}

				if( total != 0 )
				{
					buffer_conta = {};
					buffer_conta.o = 'créance';
					buffer_conta.a = date;
					buffer_conta.b = findCompte( 'créance');
					buffer_conta.c = buffer.number;
					buffer_conta.d = 'mode P + '+client;
					buffer_conta.e = total.toFixed(root.devise.nb_decimal);
					tab_return.push( buffer_conta );

					table_liste_compte.push({'name':'créance'});
				}

				table_liste_compte.push({'name':'créance'});
			}
		}
	}


	//gestion tableaux des comptes
	var msg_buffer = '<span style="color:#12999D;cursor:pointer;">Indiquez le numéro de compte</span>';

	//suppression des doublonns
	var tab = [];
	var buffer;
	for( i=0; i<table_liste_compte.length; i++ )
	{
		var ok = true;
		buffer = table_liste_compte[i];
		buffer.value = msg_buffer;
		buffer.uid   = '';

		for( p=0; p<tab.length; p++ )
		if( tab[p].name == buffer.name)
			ok = false;

		if(ok)
			tab.push(buffer);
    }
    
	//Get compte déja enregistré
    for( i=0; i<GLOBAL_VAR_COMPTE.length; i++ )
    {
        buffer = GLOBAL_VAR_COMPTE[i];

        for( p=0; p<tab.length; p++ )
        if( tab[p].name == buffer.name)
        {
            tab[p].value = buffer.value;
            tab[p].uid   = buffer.uid;
        }
    }

    //Print
    root.compte_list = tab;
	root.visualisation_list = tab_return;
	
	root.chargement = false;
}

</script>